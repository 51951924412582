$white: #fff;
$black: #2f2f2f;
$lightgrey: #eee;

$color-primary: #15256a;
$color-secondary: #991f13;
$color-grey: #e6e6e6;
$color-success: #28a745;
$color-danger: #dc3545;

$theme-colors: (
    "primary": $color-primary,
    "secondary": $color-secondary,
    "success": $color-success,
    "danger": $color-danger,
);

$link-colour: $black;
