html,
body {
    font-family: $font-body;
    color: $black;
    height: 100%;
    // padding-top: 5rem;
}

main {
    padding-top: 2rem;
}

h1 {
    font-family: $font-main-heading;
    margin-bottom: 1.5rem;
    color: $black;
}

h2 {
    font-family: $font-sub-heading;
    margin-bottom: 1.5rem;
    color: $black;
}

h3 {
    font-family: $font-main-heading;
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
    color: $black;
}

a {
    transition: all 300ms ease-in-out;
    color: #15265a;
    
    @include themify($themes) {
        color: themed('link-colour');
    }

    &:hover {
        text-decoration: none;
        transform: translateY(1px);

        @include themify($themes) {
            color: darken(themed('link-colour'), 10%);
        }
    }
}

ul {
    padding-left: 0;

    li {
        list-style-position: inside;
    }
}

