.carousel-item img {
    width: 100%;
}
.carousel-caption {
    max-width: 300px;
    padding: 20px;
    background-color: rgba(0,0,0,0.5);
    border-radius: 3px;
    bottom: 60px;
    h1 {
        color: #fff;
    }
}