.o-objectfit {
    height: 100%;
    overflow: hidden;

    &.o-objectfit--compat {
        background-size: cover;
        background-position: center center;
        
        img {
            opacity: 0;
        }
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}