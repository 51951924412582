.navbar {
    background-color: $black;
    color: $white;
}

.navbar-brand {
    color: $white;

    &:hover {
        color: $white;
    }
}

.nav-link {
    color: $white;

    &:hover {
        color: $white;
    }
}