.o-layout {
    position: relative;
    display: flex;
    flex-flow: column;
    height: 100vh;
}

.o-layout__main {
    padding-top: 116px;
    flex: 1 0 auto;
}

.o-layout__section {
    padding: 2rem 0;

    @include media-breakpoint-up(md) {
        padding: 3rem 0;
    }
}

.o-layout__section--alt {
    background-color: $lightgrey;
}