@mixin aspectRatio($width, $height) {
    position: relative;

    &:before {
        display: block;
        content: "";
        width: 100%;
        padding-top: ($height / $width) * 100%;
    }
}

@mixin translateX($x) {
    -webkit-transform: translateX($x);
    -ms-transform: translateX($x); // IE only
    -o-transform: translateX($x);
    transform: translateX($x);
}

@mixin translateY($y) {
    -webkit-transform: translateY($y);
    -ms-transform: translateY($y); // IE only
    -o-transform: translateY($y);
    transform: translateY($y);
}

@mixin translate($x, $y) {
    -webkit-transform: translate($x, $y);
    -ms-transform: translate($x, $y); // IE only
    -o-transform: translate($x, $y);
    transform: translate($x, $y);
}

@mixin rotate($degrees) {
    -webkit-transform: rotate($degrees);
    -ms-transform: rotate($degrees); // IE only
    -o-transform: rotate($degrees);
    transform: rotate($degrees);
}