@media print {
    @page {
        size: 330mm 427mm;
        margin: 14mm;
    }

    // div {
    //     border: 0.5px solid black;
    // }

    .navbar-nav,
    #c-backToTop,
    .c-product__section--cta,
    .c-product-review-form,
    .c-product-find,
    .c-product-find__slide,
    .c-product__section--reviews,
    .c-product__section--downloads,
    .c-product-reviews,
    .c-product__image-enlarge,
    .c-product-downloads,
    .c-inspiration-page__section .c-cta,
    .c-social,
    .c-btn,
    .c-footer,
    .c-footer__menu,
    .c-footer__connect,
    .c-footer__sites,
    .c-newsletter,
    .c-comments,
    .c-header-search__toggle {
        display: none !important;
    }

    .navbar {
        background-color: transparent;

        &.fixed-top {
            z-index: 1;
        }
    }

    .c-product-reviews,
    .c-product-reviews__item {
        page-break-inside: avoid;
        position: relative;
        z-index: 2;
    }

    .c-product__section, .c-product__section--alt, .c-inspiration-page__section, .c-inspiration-page__section-alt {
        page-break-inside: avoid;
        print-color-adjust: exact;
        -webkit-print-color-adjust: exact;
    }

}