@mixin aspectRatioContent() {
    @include aspectRatio(1, 1);

    > .o-aspect-ratio__content {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
}

.o-aspect-ratio {
    @include aspectRatioContent();
}

.o-aspect-ratio-md {
    @include media-breakpoint-up(md) {
        @include aspectRatioContent();
    }
}

.o-aspect-ratio--16by9 {
    @include aspectRatio(16, 9);
}