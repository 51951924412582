.o-select {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    &:after {
        @include fa-icon;
        @extend .fas;
        font-size: 18px;
        margin-left: -1rem;
        content: fa-content($fa-var-angle-down);
        pointer-events: none;
    }
}

.o-select select {
    padding: 0;
    padding-right: 1rem;
    width: 130%;
    border: none;
    box-shadow: none;
    background: transparent;
    background-image: none;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;

    &::-ms-expand {
        display: none;
    }
}

.o-select select:focus {
    outline: none;
}