//-----------------------------
// REGISTRATION FORMS START
//-----------------------------

.registration-form-submit {
    font-size: 20px;
    margin-top: 1rem;
    padding: 0.5rem 2rem;
}


.multi-choice-label {
    font-size: 20px;
}

//-----------------------------
// REGISTRAION FORMS END
//-----------------------------




//-----------------------------
// GENERAL START
//-----------------------------

.page-title {
    color: #2f2f2f;
    font-size: 30px;
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
}

.btn-secondary {
    background-color: #fff !important;
    color: #2f2f2f !important;
    min-width: 80px;
    &:hover {
        background-color: #555555 !important;
        color: #fff !important;
    }
}

//-----------------------------
// GENERAL END
//-----------------------------
