.u-phone,
.u-website,
.u-address {
    &:before {
        @include fa-icon;
        @extend .fas;
        content: fa-content($fa-var-phone);
        vertical-align: initial;
        margin-right: .5rem;
        font-size: .7rem;
    }
}

.u-website {
    &:before {
        content: fa-content($fa-var-globe);
    }
}

.u-address {
    &:before {
        content: fa-content($fa-var-map-marker-alt);
        margin-right: .7rem;
    }
}
