.map {
    position: absolute;
    z-index: -1;
    top: 0;
    width: 100%;
    height: 100vh;
}

.map--admin {
    position: relative;
    z-index: 1;
    height: 500px;
}